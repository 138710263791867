export const printIcon = (size = 24) => <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 48 48'>
	<rect x='9' y='11' fill='#424242' width='30' height='3' />
	<path fill='#616161' d='M4,25h40v-7c0-2.2-1.8-4-4-4H8c-2.2,0-4,1.8-4,4V25z' />
	<path fill='#424242' d='M8,36h32c2.2,0,4-1.8,4-4v-8H4v8C4,34.2,5.8,36,8,36z' />
	<circle fill='#00E676' cx='40' cy='18' r='1' />
	<rect x='11' y='4' fill='#90CAF9' width='26' height='10' />
	<path fill='#242424' d='M37.5,31h-27C9.7,31,9,30.3,9,29.5v0c0-0.8,0.7-1.5,1.5-1.5h27c0.8,0,1.5,0.7,1.5,1.5v0 C39,30.3,38.3,31,37.5,31z' />
	<rect x='11' y='31' fill='#90CAF9' width='26' height='11' />
	<rect x='11' y='29' fill='#42A5F5' width='26' height='2' />
	<rect fill='#1976D2' x='16' y='33' width='17' height='2' />
	<rect fill='#1976D2' x='16' y='37' width='13' height='2' />
</svg>;