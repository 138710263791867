import { getBorderCSS, getColorsCSS, getShadowCSS, getSpaceCSS, getTypoCSS } from '../../Components/Helper/getCSS';

const Style = ({ attributes, clientId }) => {
	const { alignment, btnTypo, btnColors, btnPadding, btnBorder, btnShadow } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS(btnTypo)?.googleFontLink}

		#ppbPrintPage-${clientId} .ppbPrintPage{
			text-align: ${alignment};
		}
		#ppbPrintPage-${clientId} .ppbPrintPage button{
			${getTypoCSS(btnTypo)?.styles}
			${getColorsCSS(btnColors)}
			padding: ${getSpaceCSS(btnPadding)};
			${getBorderCSS(btnBorder)}
			box-shadow: ${getShadowCSS(btnShadow)};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;