import { render } from 'react-dom';

import './style.scss';
import Style from './Style';
import { printIcon } from './utils/icons';

// const printSection = printSelector => {
// 	const bodyContent = document.body.innerHTML;
// 	const printContents = printSelector && document.querySelector(printSelector);

// 	document.body.innerHTML = '';
// 	document.body.appendChild(printContents);

// 	window.print();

// 	document.body.innerHTML = bodyContent;
// 	location.reload();
// }
const printSection = (printSelector, isModal) => {
	const bodyContent = document.querySelectorAll('body > div');
	const printContents = printSelector && document.querySelector(printSelector);
	const printContentsParent = printContents.parentElement;

	if (isModal) {
		bodyContent.forEach(div => {
			document.body.removeChild(div);
		});
		document.body.appendChild(printContents);

		window.print();

		setTimeout(() => {
			document.body.removeChild(printContents);
			bodyContent.forEach(div => {
				document.body.appendChild(div);
			});
			printContentsParent.appendChild(printContents);
		}, 1000);
	} else {
		document.body.innerHTML = '';
		document.body.appendChild(printContents);

		window.print();

		document.body.innerHTML = bodyContent;
		location.reload();
	}
}

// Print Page
document.addEventListener('DOMContentLoaded', () => {
	const allPrintPage = document.querySelectorAll('.wp-block-ppb-print-page');
	allPrintPage.forEach(printPage => {
		const attributes = JSON.parse(printPage.dataset.attributes);
		const { isPrintSection, sectionSelector, isModal, isIcon, isText, btnText } = attributes;

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<div className='ppbPrintPage'>
				<button>
					{isIcon && printIcon(30)}

					{isText && btnText && <span tagName='span' dangerouslySetInnerHTML={{ __html: btnText }} />}
				</button>
			</div>
		</>, printPage);

		// Event fire on print button
		const printBtn = document.querySelector(`#${printPage.id} .ppbPrintPage button`);
		printBtn && printBtn.addEventListener('click', e => {
			e.preventDefault();

			isPrintSection && sectionSelector ? printSection(sectionSelector, isModal) : window.print();
		});

		printPage?.removeAttribute('data-attributes');
	});
});